import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, NavLink } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import { Table as MuiTable, TableHead, TableRow, TableCell, TableBody, Grid } from '@mui/material';
import './Table.css'; // Importa el archivo CSS para estilos personalizados
import useAxios     from '../../hooks/useAxios';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
//import DeleteIcon from '@mui/icons-material/Delete';
import CommentIcon from '@mui/icons-material/Comment';
import SearchIcon from '@mui/icons-material/Search';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';


import Search from '../Input/Search';
import Dialog from '../Dialog';
import Comentarios from '../Comentarios';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link } from 'react-router-dom';


let getInit         =   null
let filter          =   ""
const queryString   =   window.location.search;
const urlParams     =   new URLSearchParams(queryString);
let current         =   (parseInt(urlParams.get('page')))?parseInt(urlParams.get('page')):1

const BACKEND       =   window.location.hostname === 'localhost' ? process.env.REACT_APP_BACKEND : process.env.REACT_APP_BACKENDREMOTE;

const Table = ({ td , endpoint, permissions, create, subFixed, skipSearch, download , upload, customAdd, href }) => {
  const navigate            =   useNavigate();
  const location            =   useLocation();
  const context             =   useContext(StateContext);
  const axios               =   useAxios();
  // Extrae los nombres de los campos del primer elemento del array de datos
  const [totalPages, setTotalPages]   =   useState(0);
  const [rows, setRows]     =   useState([]);
  const [open, setOpen]     =   useState(false);
  const [endpointState, setEndpointState]     =   useState(false);
  const [_id, set_id]       =   useState(0);

  getInit=(mounted,new_url_set)=>{

    if (!mounted) {
      return false
    }

    context.setLoadingTable(true)
    if (filter || filter==='') {
      setTotalPages(0)
    }

    let endpoint_ = "api/"+process.env.REACT_APP_BACKEND_VERSION+location.pathname+location.search


    let ext       = ""

    if (filter===undefined) {
      filter=""
    }

    if (subFixed) {
      ext       =   subFixed
    }

    if (!endpoint_.includes("?page")) {
      ext   +=  "?search="+filter
    }else {
      ext   +=  "&search="+filter
    }

    if (endpoint) {
      endpoint_ =  endpoint
    }

    setEndpointState(endpoint_+ext)


    //eventos/evento-1
    const str   = endpoint_;
    const regex = /evento-(\d+)/;
    const match = str.match(regex);
    if (!_id&&match&&match[1]) {
      set_id(parseInt(match[1]))
    }

    axios.getData({},new_url_set||endpoint_+ext).then((response)=>{

      if (response&&response.status&&response.status===401&&response.statusText) {
        context.setOpenMessage({message:"Tu sesión ha caducado...",severity:"error"})
        context.setUser(false)
        navigate("/dashboard")
      }

      if (response&&(response.status===500 || response.code==="ERROR")) {
        context.setHide(500)
        let message   = response.statusText

        if (response.data && response.data.message) {
            message   = response.data.message
        }
        context.setOpenMessage({message:"Error 500 "+message,severity:"error"})
      }

      if (response&&response.status===404) {
        context.setHide(404)
        context.setOpenMessage({message:"Presentamos problemas para comunicarnos con la nube, por favor intenta en unos minutos...",severity:"error"})
      }

      if (response&&response.data&&response.data.exception&&response.data.message) {
        context.setHide(403)
        context.setOpenMessage({message:"Error 403 "+response.data.message,severity:"error"})
      }


      if (response&&response.data && response.data.data) {
        setRows(response.data.data)
      }
      if (response&&response.data && response.data.links) {
        const calcular    =   Math.ceil(response.data.total / parseInt(response.data.per_page))
        setTotalPages(calcular)
      }

      context.setLoadingTable(false)

    })

  }

  useEffect(() => {
    setEndpointState(false)
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  // Verifica si no se proporcionaron datos válidos
  if (!td || td.length === 0) {
    return <Grid align="center">No se pueden mostrar los datos. Los campos son incompletos.</Grid>;
  }

  const onChange=(event,page)=>{
    //console.log(subFixed);
    let new_url_set
    if (!subFixed) {
      location.search="?page="+page
      navigate(location.pathname+"?page="+page)
    }else {
      new_url_set   = endpointState+"&page="+page
    }
    getInit(true,new_url_set)
  }

  const onChangeSearch=(event)=>{
    filter=event.target.value
  }

  const handleEventSearch=(bool)=>{
    if (!bool) {
      filter=""
    }
    getInit(true)
  }

  const handleEvent = (action) => {
    setOpen(action)
  };

  const handleEventAdd = (action) => {
    const new_item = {
                        "label": "Agregar",
                        "action": "create",
                        "id": 0,
                        "ma_estatus_id": 8,
                        "user_id": 0,
                        "name": "",
                        "website": "",
                        "email": "",
                        "celular": "",
                        "component":create,
                        "rif": "",
                        "direccion": "",
                        "avatar": "",
                        "created_at": "",
                        "updated_at": "",
                        "fecha": ""
                      }
    setOpen(new_item)
  };

  //console.log(permissions.find(search=>search==="create"));

  return (
    <>
      <Dialog open={open} setOpen={setOpen} getInit={getInit}/>

      {
        skipSearch!=="all"&&(
          <Grid container justifyContent="end">
            <Grid xs={12} md={4} item>
              <Grid className="search">
                <Search getInit={getInit}
                        customAdd={customAdd?endpointState:false}
                        href={href||false}
                        upload={upload?endpointState:false}
                        download={download?endpointState:false}
                        skipSearch={skipSearch}
                        add={permissions.find(search=>search==="create")&&!customAdd?handleEventAdd:false}
                        handleEventSearch={handleEventSearch}
                        fullWidth
                        label="Buscar..."
                        name="search"
                        onChange={onChangeSearch}/>
              </Grid>
            </Grid>
          </Grid>
        )
      }

      <MuiTable size="small">
        <TableHead>
          <TableRow>
            {td.map((column, index) => (
              <TableCell
                key={index}
                className={index === td.length - 1 ? '' : ''}
                width={index === td.length - 1 ? '120px' : ''}
              >
                {
                  /*VERIFICO SI TENGO PERMISOS DE VER CADA PERFIL*/
                }
                {
                  ((column.label==='Acción'&&permissions.find(search=>search==="show"))|| column.label!=='Acción')&&(
                    <Grid align={index === td.length - 1 ? 'center' : td.align}>
                      {column.label}
                    </Grid>
                  )
                }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 && context.loadingTable===false? (
            rows.map((item, index) => (
              <TableRow key={index}>
                {
                  td.map((column, columnIndex) => {


                    if (!permissions.find(search=>search==="show" || search==="edit")) {
                      return false
                    }

                    /*VERIFICO SI TENGO PERMISOS DE VER CADA PERFIL*/

                    return <TableCell
                              key={columnIndex}
                              align={column.align}
                              className={columnIndex === td.length - 1 ? 'last-column' : ''}
                            >

                              {column.field && column.field!=='url' ? item[column.field] : null}

                              {
                                column.field && column.field==='url'?<>
                                  <a rel="noreferrer" target="_blank" href={item[column.field]}>
                                    <SearchIcon/>
                                  </a>
                                </>:<>
                                  {column.event && column.event.length > 0 ? (
                                    <Grid sx={{display:"flex"}} justifyContent="center">
                                      {column.event.map((event, eventIndex) =>
                                        {

                                          if (event.component) {

                                            if (permissions.find(search=>search==="show" ) && event.label==="Ver") {
                                              return  <Grid variant="link" title={event.label}   key={eventIndex} onClick={() => handleEvent({...event,...item})}>
                                                        <SearchIcon className="cursor-pointer" title={event.label}/>
                                                      </Grid>
                                            }
                                            if (permissions.find(search=>search==="show" ) && event.label==="Inmuebles") {
                                              return  <Grid variant="link" title={event.label}   key={eventIndex} onClick={() => handleEvent({...event,...item})}>
                                                        <ChecklistRtlIcon className="cursor-pointer" title={event.label}/>
                                                      </Grid>
                                            }
                                            if (permissions.find(search=>search==="edit" ) && event.label==="Editar") {
                                              return  <Grid variant="link"  title={event.label}  key={eventIndex} onClick={() => handleEvent({...event,...item})}>
                                                        <EditIcon  className="cursor-pointer" title={event.label}/>
                                                      </Grid>
                                            }

                                            if (event.label==="Comentarios") {
                                              return  <Grid variant="link" title={event.label}   key={eventIndex} onClick={() => handleEvent({...event,component:Comentarios,...item})}>
                                                        <CommentIcon  className="cursor-pointer" title={event.label}/>
                                                      </Grid>
                                            }

                                            if (event.label==="PDF") {
                                              return  <Link variant="link" title={event.label} key={eventIndex} to={BACKEND+"api/PDF?colletion="+item.token+"&e="+_id} target="_blank" rel="noopener noreferrer">
                                                        <PictureAsPdfIcon  className="cursor-pointer" title={event.label}/>
                                                      </Link>
                                            }



                                          }

                                          if (event.byIndex&&item[event.byIndex.key]) {
                                            if (permissions.find(search=>search==="show") && event.label==="Ver" ) {
                                              return  <NavLink variant="link" to={(event.byIndex&&event.byIndex.prefixed?event.byIndex.prefixed+'-':'/')+item[event.byIndex.key]}  key={eventIndex}>
                                                        {event.label==="Ver"&&(<SearchIcon className="cursor-pointer" title={event.label}/>)}
                                                      </NavLink>
                                            }
                                            if (permissions.find(search=>search==="edit") && event.label==="Editar") {
                                              return  <NavLink variant="link" to={(event.byIndex&&event.byIndex.prefixed?event.byIndex.prefixed+'-':'/')+item[event.byIndex.key]}  key={eventIndex}>
                                                        {event.label==="Editar"&&(<EditIcon  className="cursor-pointer" title={event.label}/>)}
                                                      </NavLink>
                                            }
                                          }

                                          return false;
                                        }

                                      )}
                                    </Grid>
                                  ) : null}
                                </>
                              }

                          </TableCell>
                    }
                  )
              }
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={td.length}>
                <Grid align="center">
                  {context.loading ? "Consultando información...":"No hay resultados que mostrar."}
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
      <Grid container direction="row">
        <Grid item xs={12}  sx={{ mt: 1,mb: 1, p:0 }}>
          <Pagination defaultPage={current}
                      onChange={onChange}
                      count={totalPages}
                      shape="rounded"
                      color="secondary"
                      siblingCount={1}
                      boundaryCount={1}/>
        </Grid>
      </Grid>
    </>
  );
};

Table.propTypes = {
  td: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string,
      className: PropTypes.string,
      align: PropTypes.oneOf(['left', 'center', 'right']),
      event: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          action: PropTypes.string.isRequired,
        })
      ),
      to: PropTypes.string,
    })
  ).isRequired,
};

Table.defaultProps = {
  td: [],
};

export default Table;
