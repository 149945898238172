import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import useAxios      from '../../hooks/useAxios';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
let getData=false
export default function Asynchronous({label,endpoint,name,defaultValue,setFormData,data,fixedData,disabled,handleOnChangeString}) {
  let topFilms                  =   [];
  getData                       =   useAxios().getData;
  const [open, setOpen]         =   React.useState(false);
  const [options, setOptions]   =   React.useState([]);
  const loading                 =   open && options.length === 0;

  React.useEffect(() => {
    if (fixedData&&options.length===0) {
      topFilms=fixedData
      setOptions(fixedData)
    }
  },[fixedData,options.length]) //pendiente porque si hace ciclos quitar

  React.useEffect(() => {
    if (!endpoint) {
      return
    }
    const getInit=()=>{
      getData({},endpoint).then((response)=>{
        if (response&&response.data) {
          setOptions(response.data);
          topFilms=response.data
        }
      })
    }
    getInit()
  },[endpoint])


  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...topFilms]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      //setOptions([]);
    }
  }, [open]);

  const handleOnChange = (event, value) => {

    if (handleOnChangeString) {
      handleOnChangeString(value&&value.name?value.name:"")
    }

    if (value&&value.id) {
      let _data         =   {...data}
          _data[name]   =   value.id
      setFormData((prevValue) => {
        return { ...prevValue,[name]:value.id };
      })
    }else {
      setFormData((prevValue) => {
        return { ...prevValue,[name]:"" };
      })
    }

  };

  return (
    <Autocomplete
      disabled={disabled}
      id="asynchronous-demo"
      onChange={handleOnChange}
      sx={{ width: "100%" }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          size={"small"}
          {...params}
          name={name}
          label={label||"Buscar..."}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
