import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import  Breadcrumbs  from  '../Breadcrumbs';
import  './dashboard.css';
const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} className="dashboard-content">
              <Grid sx={{mb:1}}>
                <Breadcrumbs/>
              </Grid>
              <Grid>
                <Grid variant="outlined" className="paper" sx={{pl:3,pt:2,pr:3,pb:2}}>
                  {props.children}
                </Grid>
              </Grid>
            </Grid>
});


export default Container
