import  React from  'react';
import  Grid  from  '@mui/material/Grid';

const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} className="formContent">
              <Grid className="formCustom">
                {props.children}
              </Grid>
            </Grid>
});


export default Container
