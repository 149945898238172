import Container from '../../components/Theme/Auth';
import Input from '../../components/Input';
import Submit from '../../components/Button/Loading';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
const Login=()=>{
  return  <Container>
            <Input size="small" fullWidth name="email" type="email" label="Correo electrónico"/>
            <Input size="small" fullWidth name="password" type="password" label="Password"/>
            <Submit fullWidth type="submit" label="Crear cuenta"/>
            <Button to="/auth" component={NavLink}>Iniciar sesión</Button>
            <Button to="/auth/forgotPassword" component={NavLink}>Recuperar contraseña</Button>
          </Container>
}
export default Login
