/*set components*/
import Home  from './Home';
import HomeBanner  from './HomeBanner';
import HomeCarousel  from './HomeCarousel';
import HomeItems  from './HomeItems';
/*set icons*/
import AccountTreeIcon from '@mui/icons-material/AccountTree';


export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: true,
    private: false,
    icon: <AccountTreeIcon />,
    title: "Proyectos",
    subtitle: "Gestión eficiente de los recursos"
  },
  {
    name: "Home",
    path: "/banner/*",
    component: HomeBanner,
    menu: true,
    private: false,
    icon: <AccountTreeIcon />,
    title: "Proyectos",
    subtitle: "Gestión eficiente de los recursos"
  },
  {
    name: "Home",
    path: "/items/*",
    component: HomeItems,
    menu: true,
    private: false,
    icon: <AccountTreeIcon />,
    title: "Proyectos",
    subtitle: "Gestión eficiente de los recursos"
  },
  {
    name: "Home",
    path: "/carousel/*",
    component: HomeCarousel,
    menu: true,
    private: false,
    icon: <AccountTreeIcon />,
    title: "Proyectos",
    subtitle: "Gestión eficiente de los recursos"
  },
  {
    name: "Home",
    path: "/seo/*",
    component: HomeItems,
    menu: true,
    private: false,
    icon: <AccountTreeIcon />,
    title: "Proyectos",
    subtitle: "Gestión eficiente de los recursos"
  },
  {
    name: "Home",
    path: "/email/*",
    component: HomeItems,
    menu: true,
    private: false,
    icon: <AccountTreeIcon />,
    title: "Proyectos",
    subtitle: "Gestión eficiente de los recursos"
  },
];
