import { NavLink } from 'react-router-dom';
import Container    from '../../../components/Theme/Dashboard';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';

const sections=[
  {
    label:"Home",
    url:"home"
  },
  {
    label:"Nosotros",
    url:"we"
  },
  {
    label:"Promocionales",
    url:"promocionales"
  },  
]

const Home=(props)=>{
  return  <Container>
            <Grid container spacing={2}>
              {
                sections.map((row,key)=>{
                  return  <Grid item xs={12} md={4} key={key}>
                            <Card variant="cardHome">
                              <CardContent>
                                <Typography variant="h5" component="div" sx={{mb:1}}>
                                  {row.label}
                                </Typography>
                                <Button component={NavLink} to={"banner/"+row.url} variant='contained' sx={{mr:1}} size='small' color="secondary">
                                  Banners
                                </Button>
                                <Button component={NavLink} to={"items/"+row.url} variant='contained' sx={{mr:1}} color="success" size='small'>
                                  Items
                                </Button>
                                <Button component={NavLink} to={"carousel/"+row.url} variant='contained' sx={{mr:1}} color="error" size='small'>
                                  Carousel
                                </Button>
                                <Button component={NavLink} to={"seo/"+row.url} variant='contained' color="error" size='small'>
                                  SEO
                                </Button>
                              </CardContent>                              
                            </Card>
                          </Grid>      
                })
              }
              <Grid item xs={12} md={4}>
                <Card variant="cardHome">
                  <CardContent>
                    <Typography variant="h5" component="div" sx={{mb:1}}>
                        Config
                    </Typography> 
                    <Button component={NavLink} to={"email/email"} variant='contained' sx={{mr:1}} size='small' color="secondary">
                      Email
                    </Button>
                  </CardContent>                              
                </Card>
              </Grid>
            </Grid>            
          </Container>
}
export default Home
