import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import  Button  from  '@mui/material/Button';
import { NavLink }  from 'react-router-dom';
import  logo  from  '../../assets/png/logo.png';
import  './home.css';
const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} className="home-content">
              <Grid className="home-center">
                <Grid align="center">
                  <img src={logo} alt="Programandoweb"/>
                </Grid>
                <Button component={NavLink} to="/auth/login" variant="contained" sx={{mt:4}}>
                  Comienza YA
                </Button>
              </Grid>
            </Grid>
});


export default Container
