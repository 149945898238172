import { createTheme } from '@mui/material/styles';
import { grey, green } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: green[300],
    },
    secondary: {
      main: grey[500],
    },
    error: {
      main: green[500],
    },
    white: {
      main: "white",
    },
    black: {
      main: "black",
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiGrid: {
      variants: [
        {
          cursor: "pointer",
          props: { variant: 'link' },
          style: {
            color: green[300],
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'cardHome' },
          style: {
            border: '2px solid rgba(0,0,0,0.1)', // Borde tenue
            borderRadius: '4px', // Bordes redondeados (opcional)
          },
        },
      ],
    },
    MuiToolbar: {
      variants: [
        {
          props: { variant: 'main' },
          style: {
            color:"#fff",
            minHeight: 40
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h5' },
          style: {
            fontWeight:"bold"
          },
        },
      ],
    },
  },
});
