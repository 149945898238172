/*set components*/
import ForgotPassword  from './ForgotPassword';
import Login  from './Login';
import Register  from './Register';

/*set icons*/
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';

export const routes_modules = [
  {
    name: "Auth",
    path: "/",
    component: Login,
    menu: true,
    private: false,
    icon: <HomeIcon />,
  },
  {
    name: "Auth",
    path: "/login",
    component: Login,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "Register",
    path: "/register",
    component: Register,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },
  {
    name: "ForgotPassword",
    path: "/forgotPassword",
    component: ForgotPassword,
    menu: true,
    private: false,
    icon: <GroupIcon />,
  },

];
